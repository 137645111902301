import React, { Component, Fragment } from 'react';

import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  Breadcrumb,
  MenuSelect,
  InfiniteHits,
  RefinementList,
  ClearRefinements,
} from 'react-instantsearch-dom';
import {
  Marker,
  Control,
  GeoSearch,
  CustomMarker,
  GoogleMapsLoader,
} from 'react-instantsearch-dom-maps';

import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { AlgoliaAutoComplete } from './AlgoliaAutocomplete';
import { AlgoliaAutoItem } from './AlgoliaAutoItem';

import {
  Page,
  ListingCard,
  IconProfileSetup,
  Breadcrumbs,
  TabNav,
  IconMapMarker,
  SliderTiles,
  NamedLink,
  Footer
} from '../../components';
import { TopbarContainer } from '..';

import { searchMapListings, setActiveListing } from './AlgoliaSearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './AlgoliaSearchPage.helpers';
import { addOrRemoveToConnected } from '../../ducks/user.duck';

import css from './AlgoliaSearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
const MAX_MOBILE_SCREEN_WIDTH = 1024;

// REACT_APP_ALGOLIA_SEARCH_API_KEY=1ebc78ee2bf3e4f93836bb22062a56da
// REACT_APP_ALGOLIA_API_KEY=dcd8686643e010baa4b39e3ff7f25e57
// REACT_APP_ALGOLIA_APP_ID=RLLWA1PPHN

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  // 'latency',
  // '6be0576ff61c053d5f9a3225e2a90f76',
  {
    _useRequestCache: true,
  }
);

const updateAfter = 700;
const searchStateToUrl = (searchState) =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

export class AlgoliaSearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      showMap: true,
      searchInURL: '',
      totalCount: 0,
      rangeValue: [config.rangeValue],
      searchState: typeof window !== 'undefined' && qs.parse(window.location.search.slice(1)),
      toggle: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    typeof window !== 'undefined' && window.addEventListener('popstate', ({ state: searchState }) => {
      this.setState({ searchState });
    });
  }

  componentDidUpdate() {
    if (typeof window != undefined) {
      setTimeout(() => {
        const searchInURL = parse(window.location.search);
        if (searchInURL) {
          if (this.state.searchInURL && (searchInURL['refinementList[categoriesLabel][0]'] && searchInURL['refinementList[categoriesLabel][0]'] == this.state.searchInURL['refinementList[categoriesLabel][0]']) || (searchInURL['refinementList[subCategoriesLabel][0]'] && searchInURL['refinementList[subCategoriesLabel][0]'] == this.state.searchInURL['refinementList[subCategoriesLabel][0]'])) {
            // do nothing
          } else if (!this.state.searchInURL && (searchInURL['refinementList[categoriesLabel][0]'] && searchInURL['refinementList[categoriesLabel][0]'] != this.state.searchInURL['refinementList[categoriesLabel][0]']) || (searchInURL['refinementList[subCategoriesLabel][0]'] && searchInURL['refinementList[subCategoriesLabel][0]'] != this.state.searchInURL['refinementList[subCategoriesLabel][0]'])) {
            this.setState({ searchInURL: { ...searchInURL } });
          } else if (this.state.searchInURL) {
            this.setState({ searchInURL: '' });
          }
        }
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.setState({ searchInURL: '' });
  }

  handleToggleState = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  onSearchStateChange = (searchState) => {
    // update the URL when there is a new search state.
    clearTimeout(this.debouncedSetState);
    this.debouncedSetState = setTimeout(() => {
      typeof window !== 'undefined' && window.history.pushState(
        searchState,
        null,
        searchStateToUrl(searchState)
      );
    }, updateAfter);

    this.setState((previousState) => {
      const hasQueryChanged =
        previousState.searchState.query !== searchState.query;

      return {
        ...previousState,
        searchState: {
          ...searchState,
          boundingBox: !hasQueryChanged ? searchState.boundingBox : null,
        },
      };
    });
  };

  handleSubmit(values) {
    const currentSearchParams = this.state.currentQueryParams;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = Object.assign(currentSearchParams, {
      bounds,
      address: search,
    });
    // const searchParams = {
    //   ...currentSearchParams,
    //   ...originMaybe,
    //   address: search,
    //   bounds,
    // }
    history.push(
      createResourceLocatorString(
        'AlgoliaSearchPage',
        routeConfiguration(),
        {},
        searchParams
      )
    );
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const AlgoliaSearchPagePath = pathByRouteName('AlgoliaSearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than AlgoliaSearchPage too
    const isAlgoliaSearchPage = currentPath === AlgoliaSearchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isAlgoliaSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('AlgoliaSearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      listings,
      sortConfig,
      filterConfig,
      scrollingDisabled,
      history,
      searchParams,
      currentUser,
      showList,
      categories,
      subCategories,
      onAddOrRemoveToConnected,
      // location,
      // params,
      // pageName,
      // subChildCategories,
      // currentUserListing,
      // currentUserListingFetched,
    } = this.props;

    if (typeof window === undefined) return null;
    const { getAlgoliaResults } = require('@algolia/autocomplete-js');
    // eslint-disable-next-line no-unused-vars
    // const { mapSearch, page, } = parse(location.search, {
    //   latlng: ['origin'],
    //   latlngBounds: ['bounds'],
    // });
    const searchInURL = typeof window != "undefined" ? parse(window.location.search) : {};

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };
    const handleShowHide = () => {
      if (this.state.showMap) {
        this.setState({ showMap: false })
      } else {
        this.setState({ showMap: true })
      }
    }

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const { searchState } = this.state;

    const parameters = {};
    if (!searchState.boundingBox) {
      parameters.aroundLatLngViaIP = true;
      parameters.aroundRadius = 'all';
      parameters.hitsPerPage = 8;
      parameters.filters = 'state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")';
    }

    const redirectTo = (link) => {
      this.onSearchStateChange({
        configure: { aroundLatLngViaIP: true, aroundRadius: 'all', hitsPerPage: 8 },
        page: 1,
        refinementList: { 'location.address': link }
      });
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.mainWrapper}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="AlgoliaSearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <div className={classNames(css.aisInstantSearch, (currentUser && currentUser.id) ? "" : css.notLoginUserSearch)}>
            <InstantSearch
              searchClient={searchClient}
              indexName={process.env.REACT_APP_ALGOLIA_LISTING_INDEX}
              searchState={searchState}
              onSearchStateChange={this.onSearchStateChange}
            >
              <Configure {...parameters} />
              <div className={classNames(css.leftPanel, this.state.showMap ? css.fullLeftPanel : "")}>
                <div className={css.breadcrumbsBox}>
                  <Breadcrumbs
                    lvl1={{ name: "AlgoliaSearchPage", label: "Search" }}
                  // lvl2={searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[categoriesLabel]']
                  //   ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[categoriesLabel]'], search: `refinementList[categoriesLabel]=${searchInURL['refinementList[categoriesLabel]']}` }
                  //   : searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[categoriesLabel][0]']
                  //     ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[categoriesLabel][0]'], search: `refinementList[categoriesLabel][0]=${searchInURL['refinementList[categoriesLabel][0]']}` }
                  //     : ''}
                  // lvl3={searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[subCategoriesLabel][0]']
                  //   ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[subCategoriesLabel][0]'], search: `refinementList[subCategoriesLabel][0]=${searchInURL['refinementList[subCategoriesLabel][0]']}` }
                  //   : ''}
                  />
                </div>
                {/* <Breadcrumb
                  attributes={[
                    'categories.label',
                    'categories.subCategory.label',
                  ]}
                  rootURL="/"
                  separator={' / '}
                  translations={{
                    rootLabel: 'Homepage',
                  }}
                /> */}
                <div className={!showList ? css.searchTabs : css.navLinksBox}>
                  <RefinementList attribute="categoriesLabel" transformItems={(items) => {
                    // refinementList%5Bcategories.label%5D%5B0%5D=Catering&page=1&configure%5BaroundLatLngViaIP%5D=true&configure%5BaroundRadius%5D=all&configure%5BhitsPerPage%5D=8
                    // const index = items.findIndex(i => i.isRefined);
                    // if (index > -1) {
                    //   if (!this.state.selectedCategory) {
                    //     this.setState({ selectedCategory: items[index].label });
                    //   }
                    // } else {
                    //   this.setState({ selectedCategory: '' });
                    // }
                    return orderBy(items, ['label', 'count'], ['asc', 'desc'])
                  }} />
                </div>
                <h2><FormattedMessage id='AlgoliaSearchPage.searchResults' values={{ totalCount: this.state.totalCount ? '(' + this.state.totalCount + ')' : '' }} /></h2>
                <div className={css.searchFilter}>
                  <div className={css.leftSearchFiler}>
                    <SearchBox
                      translations={{
                        placeholder: 'Keyword',
                      }}
                    />
                    <MenuSelect
                      limit={30}
                      attribute={"region"}
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      translations={{ seeAllOption: intl.formatMessage({ id: "AlgoliaSearchPage.byRegion" }) }}
                    />
                    <MenuSelect
                      limit={30}
                      attribute={"city"}
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      translations={{ seeAllOption: intl.formatMessage({ id: "AlgoliaSearchPage.byCity" }) }}
                    />
                    {/* <div className={css.filterBox}
                      onClick={() => {
                        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
                      }}
                    >
                      <IconProfileSetup type="filter" />
                    </div> */}

                    <ClearRefinements />
                  </div>
                  <div className={css.mapToogleButton}>
                    <div className={css.mapLabel}>
                      <FormattedMessage id={this.state.showMap ? 'AlgoliaSearchPage.showMap' : 'AlgoliaSearchPage.hideMap'} />
                    </div>
                    <div className={css.checkboxWrapper}
                      onClick={handleShowHide}>
                      <div
                        className={classNames(css.ball, {
                          [css.toggled]: !this.state.showMap,
                        })}
                      >
                        {' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.selectCategoryData}>
                  <div>
                    <div className="ais-RefinementList-list">
                      <div className={"ais-RefinementList-item " + ((searchInURL && Object.keys(searchInURL).length == 0) || (searchInURL && searchInURL["refinementList[subCategoriesLabel]"] == "") ? "ais-RefinementList-item--selected" : "")} onClick={() => history.push(
                        createResourceLocatorString(
                          'AlgoliaSearchPage',
                          routeConfiguration(),
                          {},
                          {}
                        )
                      )}>
                        <label className="ais-RefinementList-label">
                          <span className="ais-RefinementList-labelText"><FormattedMessage id='AlgoliaSearchPage.all' /></span>
                          <span> </span>
                          <span className="ais-RefinementList-count">
                            {this.state.totalCount ? this.state.totalCount : ''}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <RefinementList attribute="subCategoriesLabel" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="location.address" transformItems={(items) => {
                    []
                    const totalCount = items.map(i => i.count).reduce((accu, curr) => accu + curr, 0);
                    if (!this.state.totalCount) {
                      this.setState({ totalCount });
                    }
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>

                <div className={css.dividerLine} />
                <div className={classNames(
                  css.cardsGrid, this.state.showMap ? css.fullCardGrid : "")}>
                  <InfiniteHits hitComponent={(props) => <ListingCard
                    {...props}
                    currentUser={currentUser}
                    categories={categories}
                    subCategories={subCategories}
                    onAddOrRemoveToConnected={onAddOrRemoveToConnected}
                  />} />
                </div>
              </div>
              {this.state.showMap || isMobileLayout
                ? null
                : <div className={css.rightPanel}>
                  <div className={css.searchMapInput}>
                    <AlgoliaAutoComplete
                      insights={true}
                      openOnFocus={true}
                      defaultActiveItemId={0}
                      debug
                      getSources={({ query }) => [
                        {
                          sourceId: 'location.address',
                          getItems() {
                            return getAlgoliaResults({
                              searchClient,
                              queries: [
                                {
                                  indexName: 'DEVListings',
                                  query,
                                },
                              ],
                            });
                          },
                          templates: {
                            item({ item, components }) {
                              // return <span
                              //   onClick={() => redirectTo(item.location.address)}
                              //   className="aa-ItemLink">
                              //   {item.location.address}
                              // </span>
                              return <AlgoliaAutoItem hit={item} redirectTo={redirectTo} components={components} />

                            },
                          },
                          getItemInputValue({ item }) {
                            return item.location.address;
                          },
                        },
                      ]}
                    />
                  </div>
                  {/* </div> */}
                  <div className={css.mapRightBar}>
                    <GoogleMapsLoader apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
                      {/* "AIzaSyBawL8VbstJDdU5397SUX7pEt9DslAwWgQ" */}
                      {(google) => (
                        <GeoSearch google={google} enableRefineOnMapMove={false}>
                          {({ hits }) => (
                            // <Fragment>
                            //   <Control />
                            hits.map((hit) => (
                              // <Marker key={hit.objectID} hit={hit} />
                              <CustomMarker key={hit.objectID} hit={hit}>
                                <span style={{ backgroundColor: "#FFF", fontSize: "1rem" }}>
                                  <IconMapMarker brand="markerone" />
                                </span>
                              </CustomMarker>
                            ))
                            // </Fragment>

                          )}
                        </GeoSearch>
                      )}
                    </GoogleMapsLoader>
                  </div>
                  <div className={css.rangeSliderBox}>
                    <div className={css.sliderHead}>
                      <div className={css.radioName}>
                        <FormattedMessage id='AlgoliaSearchPage.adjustRadius' />
                      </div>
                      <div className={css.checkboxWrapper}
                        onClick={() => this.handleToggleState()}>
                        <div
                          className={classNames(css.ball, {
                            [css.toggled]: this.state.toggle,
                          })}
                        >
                          {' '}
                        </div>
                      </div>
                    </div>
                    <SliderTiles
                      rangeValue={this.state.rangeValue}
                      setRangeValue={(e) => { this.setState({ rangeValue: [e] }) }}
                    />
                  </div>
                </div>}
            </InstantSearch>
          </div>
        </div >
        <Footer />
      </Page >
    );
  }
}

AlgoliaSearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
  initialSearchFormValues: {},
};

AlgoliaSearchPageComponent.propTypes = {
  // listings: array,
  // mapListings: array,
  // onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  // onSearchMapListings: func.isRequired,
  // pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  // searchInProgress: bool.isRequired,
  // searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  initialSearchFormValues: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onAddOrRemoveToConnected: (listingId, isAddedPreferred, businessName) => dispatch(addOrRemoveToConnected(listingId, isAddedPreferred, businessName))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AlgoliaSearchPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AlgoliaSearchPageComponent);

export default AlgoliaSearchPage;
